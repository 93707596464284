import { useState, useEffect, ReactNode } from 'react';
import {
  EuiButtonIcon,
  EuiErrorBoundary,
  EuiScreenReaderOnly,
  DefaultItemAction,
  EuiTableComputedColumnType,
  RIGHT_ALIGNMENT,
  EuiSearchBarProps,
  EuiInMemoryTable,
  EuiConfirmModal,
  EuiOverlayMask,
} from '@elastic/eui';
import { SntExpandSection } from 'layout/config/snt/requests/table/expand-section/expand-section';
import {
  GroupOperationStatus,
  RequestMessage,
  StrawberryGroupOperationResponse,
  RequestType,
} from 'gqlHooks';

import { GROUPS_CMD_CFG_SUMMARY_COLUMNS } from './table-columns';

interface ItemIdToExpandedRowMap {
  [id: string]: ReactNode;
}

export interface SntConfigRequestsTableProps {
  /** True if the table should show a loading status */
  isLoading: boolean;
  /** Requests to be displayed in the table*/
  requests: StrawberryGroupOperationResponse[];
  /** Method invoked when the users wish to cancel a group request */
  onRequestCancel: (requestId: number) => void;
  /** Opens the details view of a request */
  onViewDetails: (requestId: number) => void;
}

/* Table for displaying message event data */
export const GroupsSntConfigRequestsTable = (props: SntConfigRequestsTableProps) => {
  // Handle tracking which rows are expanded in the table
  const [itemIdToExpandedRowMap, setItemIdToExpandedRowMap] = useState<ItemIdToExpandedRowMap>({});
  const [isCancelDialogOpen, setIsCancelDialogOpen] = useState(false);
  const [requestToCancel, setRequestToCancel] = useState<StrawberryGroupOperationResponse>();

  // Generates the expand section data for the messages
  useEffect(() => {
    const updatedItems: ItemIdToExpandedRowMap = {};

    setItemIdToExpandedRowMap(updatedItems);
  }, [props.requests]);

  const toggleDetails = (message: StrawberryGroupOperationResponse) => {
    const itemIdToExpandedRowMapValues: ItemIdToExpandedRowMap = {
      ...itemIdToExpandedRowMap,
    };
    const key: number = message.requestId;
    if (itemIdToExpandedRowMapValues[key]) {
      delete itemIdToExpandedRowMapValues[key];
    } else {
      itemIdToExpandedRowMapValues[key] = (
        // As this is the cmd table, the request type should always be a RequestMessage
        // so we can cast the request to that specific type and reuse the SntExpandSection for display
        <SntExpandSection
          fullMessage={message}
          request={message.request as RequestMessage}
        />
      );
    }
    setItemIdToExpandedRowMap(itemIdToExpandedRowMapValues);
  };

  const handleDetailOpen = (request: StrawberryGroupOperationResponse) => {
    props.onViewDetails(request.requestId);
  };

  // Extracting unique request types
  const requestTypes = Object.values(RequestType);

  // Action for expanding/collapsing the additional information section
  const expandActionButton = (isExpanded: boolean, message: StrawberryGroupOperationResponse) => {
    return (
      <EuiButtonIcon
        aria-label={isExpanded ? 'Collapse' : 'Expand'}
        iconType={isExpanded ? 'arrowDown' : 'arrowRight'}
        onClick={() => toggleDetails(message)}
      />
    );
  };

  const expandAction: EuiTableComputedColumnType<StrawberryGroupOperationResponse> = {
    name: (
      <EuiScreenReaderOnly>
        <span>Expand rows</span>
      </EuiScreenReaderOnly>
    ),
    align: RIGHT_ALIGNMENT,
    width: '40px',
    isExpander: true,
    render: (message: StrawberryGroupOperationResponse) =>
      expandActionButton(message.requestId in itemIdToExpandedRowMap, message),
  };

  //Opens the "cancel group request" dialog
  const onOpenCancelDialog = (request: StrawberryGroupOperationResponse) => {
    setRequestToCancel(request);
    setIsCancelDialogOpen(true);
  };

  // Closes the "cancel group request" dialog
  const onCloseCancelDialog = () => {
    setRequestToCancel(undefined);
    setIsCancelDialogOpen(false);
  };

  const onConfirmCancel = () => {
    if (requestToCancel) {
      props.onRequestCancel(requestToCancel.requestId);
    }
    onCloseCancelDialog();
  };

  const cancelRequestDialog = (
    <EuiOverlayMask>
      <EuiConfirmModal
        buttonColor="danger"
        cancelButtonText="Cancel"
        confirmButtonText="Ok"
        defaultFocusedButton="confirm"
        onCancel={onCloseCancelDialog}
        onConfirm={onConfirmCancel}
        title="Are you sure?"
      >
        <p>{`Cancel this group configuration request?`}</p>
      </EuiConfirmModal>
    </EuiOverlayMask>
  );

  const cancelAction: DefaultItemAction<StrawberryGroupOperationResponse> = {
    name: (
      <EuiScreenReaderOnly>
        <span>Cancel Request</span>
      </EuiScreenReaderOnly>
    ),
    enabled: (request: StrawberryGroupOperationResponse) => {
      const requestState = request.requestState;
      return (
        requestState === GroupOperationStatus.Active ||
        requestState === GroupOperationStatus.Applying
      );
    },
    description: 'Cancel Configuration Request',
    icon: 'trash',
    color: 'danger',
    type: 'icon',
    onClick: onOpenCancelDialog,
    isPrimary: true,
  };

  const openDetailsAction: DefaultItemAction<StrawberryGroupOperationResponse> = {
    name: (
      <EuiScreenReaderOnly>
        <span>View Request Details</span>
      </EuiScreenReaderOnly>
    ),
    description: 'View Request Details',
    icon: 'iInCircle',
    color: 'primary',
    type: 'icon',
    onClick: handleDetailOpen,
    isPrimary: true,
  };

  const actions = [cancelAction, openDetailsAction];

  const search: EuiSearchBarProps | undefined = {
    box: {
      incremental: true,
    },
    filters: [
      {
        type: 'field_value_selection',
        field: 'request.type',
        name: 'Request Type',
        multiSelect: false,
        options: requestTypes.map(type => ({
          value: type,
        })),
      },
    ],
  };

  return (
    <EuiErrorBoundary id="snt-config-requests-table">
      {isCancelDialogOpen ? cancelRequestDialog : null}
      <EuiInMemoryTable
        columns={[
          ...GROUPS_CMD_CFG_SUMMARY_COLUMNS,
          {
            name: 'Actions',
            actions,
          },
          expandAction,
        ]}
        hasActions
        search={search}
        itemId={'requestId'}
        itemIdToExpandedRowMap={itemIdToExpandedRowMap}
        items={props.requests ?? []}
        loading={props.isLoading}
        tableCaption="Group config requests"
      />
    </EuiErrorBoundary>
  );
};

export default GroupsSntConfigRequestsTable;
