import { useState } from 'react';
import { noop } from 'lodash';
import {
  EuiErrorBoundary,
  EuiSelect,
  useGeneratedHtmlId,
  EuiSelectOption,
  EuiFormRow,
} from '@elastic/eui';
import { DeviceType } from 'app/types';
import { GetDeviceTypesQueryVariables, useGetDeviceTypesQuery } from 'app/generated/graphql';

export type SelectDeviceTypeProps = {
  // onChange and onBlur are sent via react-hook-forms Controller
  onChange: (deviceType: string) => void;
  // pass onBlur if validating mode = onBlur or all
  onBlur?: (event: string | React.ChangeEvent<Element>) => void;
  selectedDeviceType?: DeviceType | string;
  isDisabled?: boolean;
  label?: string;
  defaultOption?: { text: string; value: string };
  hasNoInitialSelection?: boolean;
  /** True if the field should display an invalid indicator */
  isInvalid?: boolean;
  /** Error message to display in relation to the invalid indicator */
  errorMessage?: string | undefined;
};

export const SelectDeviceType = (props: SelectDeviceTypeProps) => {
  const [options, setOptions] = useState<EuiSelectOption[]>([]);

  const queryVars: GetDeviceTypesQueryVariables = {
    limit: 1000,
  };

  const { loading: deviceTypesLoading, error } = useGetDeviceTypesQuery({
    variables: queryVars,
    onCompleted: data => {
      const opts = data?.deviceTypes?.items?.map(deviceType => ({
        text: deviceType.label ?? '',
        value: deviceType.identifier ?? '',
      }));

      setOptions(opts);
    },
  });

  const handleOnChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    // Invoke the parent's onChange function with the selected value
    props.onChange(event.target.value);
  };

  const errorLoading = error ? 'Failed to load device types' : '';
  const errors = [errorLoading, props.errorMessage];

  return (
    <EuiErrorBoundary>
      <EuiFormRow
        label="Device Type"
        isInvalid={props.isInvalid || errorLoading !== ''}
        error={errors}
        aria-required={props.isInvalid}
      >
        <EuiSelect
          id={useGeneratedHtmlId({ prefix: 'selectDeviceType' })}
          options={options}
          value={props.selectedDeviceType}
          onChange={handleOnChange}
          hasNoInitialSelection
          onBlur={props.onBlur ?? noop}
          disabled={props.isDisabled}
          aria-label={`Select ${props.label ?? 'deviceType'}`}
          isLoading={deviceTypesLoading}
        />
      </EuiFormRow>
    </EuiErrorBoundary>
  );
};
