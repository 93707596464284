import { useState, useEffect, ReactNode } from 'react';
import {
  EuiScreenReaderOnly,
  EuiTableComputedColumnType,
  EuiButtonIcon,
  EuiErrorBoundary,
  RIGHT_ALIGNMENT,
  EuiSearchBarProps,
  EuiInMemoryTable,
  EuiSearchBar,
  EuiCallOut,
  EuiSearchBarOnChangeArgs,
  EuiTableFieldDataColumnType,
  EuiButton,
  SearchFilterConfig,
} from '@elastic/eui';
import ResponsivePanel from 'layout/panel/responsive-panel';
import ThemedJson from 'layout/json/themed-json';
import { type Messages } from 'gqlHooks';

interface ItemIdToExpandedRowMap {
  [id: string]: ReactNode;
}

interface MessagesTableProps {
  /**
   * Error text to display in the table if something is wrong
   */
  errorMessage?: string;
  /**
   * True if the table should show a loading status
   */
  isLoading?: boolean;
  /**
   * Messages to be displayed in the table
   */
  messages?: Messages[];
  /** Columns of the table */
  columns: EuiTableFieldDataColumnType<Messages>[];
  /** Optionsl extra filters to display with the search bar */
  filters?: SearchFilterConfig[];
  /**
   * Message data to be used for exporting to csv
   */
  setMessagesQuery: (message: Messages[]) => void;
}

/* Table for displaying message event data */
export default function MessagesAndSimTable(props: MessagesTableProps) {
  // Handle tracking which rows are expanded in the table
  const [itemIdToExpandedRowMap, setItemIdToExpandedRowMap] = useState<ItemIdToExpandedRowMap>({});

  // Override for table rows expansion state - used for expand/collapse all entries
  // When changed, all table rows will get this value as their new "default"
  // causing them to toggle their open/close as a whole
  const [expandAll, setExpandAll] = useState(false);

  // Handles the values provided by the search box
  const [query, setQuery] = useState(EuiSearchBar.Query.MATCH_ALL);
  const [error, setError] = useState<Error | null>(null);

  // Custom render so invalid search text errors are more visible for users
  const renderError = () => {
    if (!error) {
      return;
    }
    return (
      <EuiErrorBoundary>
        <EuiCallOut
          size="s"
          iconType="faceSad"
          color="danger"
          title={`Invalid search: ${error?.message ?? ''}`}
        />
      </EuiErrorBoundary>
    );
  };

  // Handles the change in text in the search box
  const onChange = ({ query, error }: EuiSearchBarOnChangeArgs) => {
    if (error) {
      setError(error);
    } else {
      setError(null);
      setQuery(query);
    }
  };

  // Generates the expand section data for the messages
  useEffect(() => {
    const updatedItems: ItemIdToExpandedRowMap = {};

    props.messages?.map((message: Messages) => {
      if (expandAll) {
        updatedItems[message.PK] = (
          <ResponsivePanel>
            <ThemedJson jsonValues={JSON.parse(message.data)} />
          </ResponsivePanel>
        );
      }
    });
    setItemIdToExpandedRowMap(updatedItems);
  }, [expandAll, props.messages, props.errorMessage]);

  // Method for updating the array of which message items are expanded
  const toggleDetails = (message: Messages) => {
    const itemIdToExpandedRowMapValues: ItemIdToExpandedRowMap = {
      ...itemIdToExpandedRowMap,
    };
    const key: string = message.PK;

    const rawData = JSON.parse(message.data);

    if (itemIdToExpandedRowMapValues[key]) {
      delete itemIdToExpandedRowMapValues[key];
    } else {
      itemIdToExpandedRowMapValues[key] = (
        <ResponsivePanel>
          <ThemedJson jsonValues={rawData} />
        </ResponsivePanel>
      );
    }
    setItemIdToExpandedRowMap(itemIdToExpandedRowMapValues);
  };

  // Action for expanding/collapsing the additional information section
  const expandActionButton = (isExpanded: boolean, message: Messages) => {
    return (
      <EuiButtonIcon
        id="expand-all-messages-table"
        aria-label={isExpanded ? 'Collapse' : 'Expand'}
        iconType={isExpanded ? 'arrowDown' : 'arrowRight'}
        onClick={() => toggleDetails(message)}
      />
    );
  };

  // Action used by the row to toggle expansion state
  const expandAction: EuiTableComputedColumnType<Messages> = {
    name: (
      <EuiScreenReaderOnly>
        <span>Expand rows</span>
      </EuiScreenReaderOnly>
    ),
    align: RIGHT_ALIGNMENT,
    width: '40px',
    isExpander: true,
    render: (message: Messages) =>
      expandActionButton(message.PK in itemIdToExpandedRowMap, message),
  };

  // Action used by the search filter to toggle expansion state of all messages
  const expandAllFilter: SearchFilterConfig = {
    type: 'custom_component',
    component: () => {
      return (
        <EuiButton
          id="expand-all-sim-table"
          iconSide="right"
          color="text"
          iconType={expandAll ? 'arrowDown' : 'arrowRight'}
          onClick={() => {
            setExpandAll(!expandAll);
          }}
        >
          {expandAll ? 'Collapse All' : 'Expand All'}
        </EuiButton>
      );
    },
  };

  const searchFilters: SearchFilterConfig[] = props.filters
    ? [...props.filters, expandAllFilter]
    : [expandAllFilter];

  const search: EuiSearchBarProps = {
    onChange: onChange,
    query: query,
    box: {
      incremental: true,
    },
    filters: searchFilters,
  };

  useEffect(() => {
    const executeQuery = EuiSearchBar.Query.execute(query, props.messages || []);
    props.setMessagesQuery(executeQuery ?? props.messages);
    // eslint really wants props
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query, props.messages]);

  return (
    <EuiErrorBoundary>
      <EuiInMemoryTable
        error={props.errorMessage}
        childrenBetween={renderError()}
        tableCaption="Messages"
        items={props.messages ?? []}
        columns={[...props.columns, expandAction]}
        search={search}
        itemIdToExpandedRowMap={itemIdToExpandedRowMap}
        pagination={true}
        sorting={true}
        // This is the Messages.PK property - interfaces don't like "toString()" methods being called on properties
        itemId={'PK'}
        loading={props.isLoading}
      />
    </EuiErrorBoundary>
  );
}
