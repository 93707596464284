import { useState, ReactNode } from 'react';
import {
  EuiButtonIcon,
  EuiErrorBoundary,
  EuiScreenReaderOnly,
  DefaultItemAction,
  EuiTableComputedColumnType,
  RIGHT_ALIGNMENT,
  EuiSearchBarProps,
  EuiInMemoryTable,
  EuiTableFieldDataColumnType,
  SearchFilterConfig,
} from '@elastic/eui';
import { SntExpandSection } from 'layout/config/snt/requests/table/expand-section/expand-section';
import { CommandConfigurationRequestRow, RequestState, RequestType } from 'gqlHooks';

import ConfirmDeleteRequest from '../confirm-delete-requests';

interface ItemIdToExpandedRowMap {
  [id: string]: ReactNode;
}

export interface SntConfigRequestsTableProps {
  /** Error text to display in the table if something is wrong */
  errorText?: string;
  /** True if the table should show a loading status */
  isLoading: boolean;
  /** Messages to be displayed in the table */
  requests: CommandConfigurationRequestRow[];
  /** Method invoked when the users wish to cancel a request */
  onRequestCancel: (requestId: number, deviceId: string) => void;
  /** Which columns should be displayed in the table */
  tableColumns: EuiTableFieldDataColumnType<CommandConfigurationRequestRow>[];
}

/* Table for displaying message event data */
export const SntConfigRequestsTable = (props: SntConfigRequestsTableProps) => {
  // Handle tracking which rows are expanded in the table
  const [itemIdToExpandedRowMap, setItemIdToExpandedRowMap] = useState<ItemIdToExpandedRowMap>({});
  const [isConfirmCancelOpen, setIsConfirmCancelOpen] = useState(false);
  const [requestToCancel, setRequestToCancel] = useState<CommandConfigurationRequestRow>();

  const toggleDetails = (request: CommandConfigurationRequestRow) => {
    const itemIdToExpandedRowMapValues: ItemIdToExpandedRowMap = {
      ...itemIdToExpandedRowMap,
    };
    const key: number = request.requestId;
    // If the row is already expanded, then the user is collapsing the row so it should be removed
    if (itemIdToExpandedRowMapValues[key]) {
      delete itemIdToExpandedRowMapValues[key];
    } else {
      itemIdToExpandedRowMapValues[key] = (
        <SntExpandSection
          request={request.request}
          requestResponse={request.responseData}
          fullMessage={request}
        />
      );
    }
    setItemIdToExpandedRowMap(itemIdToExpandedRowMapValues);
  };

  // Invoked if the user closes out of the "confirm cancelling a request" dialog
  const onClose = () => {
    setIsConfirmCancelOpen(false);
    setRequestToCancel(undefined);
  };

  // Invoked when the user confirms that they wish to cancel an active request
  const confirmCancelRequest = () => {
    // This should always have a value at this point, TS wants the check for safety
    if (requestToCancel) {
      props.onRequestCancel(requestToCancel?.requestId, requestToCancel?.deviceId);
    }
    onClose();
  };

  // Action for expanding/collapsing the additional information section of a row
  const expandActionButton = (isExpanded: boolean, request: CommandConfigurationRequestRow) => {
    return (
      <EuiButtonIcon
        id="snt-config-requests-expand-all"
        aria-label={isExpanded ? 'Collapse' : 'Expand'}
        iconType={isExpanded ? 'arrowDown' : 'arrowRight'}
        onClick={() => toggleDetails(request)}
      />
    );
  };

  // Opens a dialog so users can confirm they wanted to actually cancel the request
  const cancelRequest = (request: CommandConfigurationRequestRow) => {
    setRequestToCancel(request);
    setIsConfirmCancelOpen(true);
  };

  // Decides if the "cancel request" action should be available for a configuration request
  const isCancelAvailable = (message: CommandConfigurationRequestRow) => {
    const requestState = message.requestState;
    return requestState == RequestState.Created;
  };

  // Expands the individual request row to display additional information
  const expandAction: EuiTableComputedColumnType<CommandConfigurationRequestRow> = {
    name: (
      <EuiScreenReaderOnly>
        <span>Expand row</span>
      </EuiScreenReaderOnly>
    ),
    align: RIGHT_ALIGNMENT,
    width: '40px',
    isExpander: true,
    render: (request: CommandConfigurationRequestRow) =>
      expandActionButton(request.requestId in itemIdToExpandedRowMap, request),
  };

  // Allows the user to cancel a request
  const cancelAction: DefaultItemAction<CommandConfigurationRequestRow> = {
    name: (
      <EuiScreenReaderOnly>
        <span>Cancel Request</span>
      </EuiScreenReaderOnly>
    ),
    enabled: isCancelAvailable,
    description: 'Cancel Configuration Request',
    icon: 'trash',
    color: 'danger',
    type: 'icon',
    onClick: cancelRequest,
    isPrimary: true,
  };

  const actions = [cancelAction];

  // Allows users to filter by configuration request type
  const requestTypeFilter: SearchFilterConfig = {
    type: 'field_value_selection',
    field: 'request.type',
    name: 'Request Type',
    multiSelect: false,
    options: Object.values(RequestType).map(type => ({
      value: type,
    })),
  };

  const search: EuiSearchBarProps = {
    box: {
      incremental: true,
    },
    filters: [requestTypeFilter],
  };

  return (
    <EuiErrorBoundary id="snt-config-requests-table">
      <ConfirmDeleteRequest
        isOpen={isConfirmCancelOpen}
        onRequestCancel={confirmCancelRequest}
        onClose={onClose}
      />
      <EuiInMemoryTable
        columns={[
          ...props.tableColumns,
          {
            name: 'Actions',
            actions,
          },
          expandAction,
        ]}
        error={props.errorText}
        hasActions
        search={search}
        itemId={'requestId'}
        itemIdToExpandedRowMap={itemIdToExpandedRowMap}
        items={props.requests ?? []}
        loading={props.isLoading}
        tableCaption="Message Events"
      />
    </EuiErrorBoundary>
  );
};

export default SntConfigRequestsTable;
