import { useEffect, useState } from 'react';
import { DeviceUploadRow } from 'app/layout/access-management/add-device-to-organization-form/upload/constants';
import {
  EuiButton,
  EuiInMemoryTable,
  EuiFlexGroup,
  EuiFlexItem,
  EuiFacetGroup,
  EuiFacetButton,
  Query,
  EuiSearchBarProps,
  EuiTableFieldDataColumnType,
} from '@elastic/eui';
// EUI isn't exporting this which is frustrating when it is the
// arguments for the search query on change method it uses
interface onChangeArgument {
  error: Error | null;
  query: Query | null;
  queryText: string;
}

type UploadPreviewTableProps = {
  /** Rows that failed to upload */
  failedRows: Array<DeviceUploadRow>;
  /** Rows whose data was deemed invalid for upload */
  invalidRows: Array<DeviceUploadRow>;
  /** True if the table should display a loading state */
  isLoading: boolean;
  /** Method invoked when the user hits the on submit button in the table header  */
  onSubmit: () => void;
  /** Rows that successfully uploaded */
  successRows: Array<DeviceUploadRow>;
  /** Rows whose data was deemed valid for upload */
  validRows: Array<DeviceUploadRow>;
  submitButtonText: string;
  tableColumns: EuiTableFieldDataColumnType<DeviceUploadRow>[];
};

const VALID_ROWS = 'valid_rows';
const INVALID_ROWS = 'invalid_rows';
const SUCCESS_ROWS = 'successful_rows';
const FAILED_ROWS = 'failed_rows';

/**
 * Displays data about the rows that will be uploaded, and the results of the upload
 */
export default function UploadPreviewTable(props: UploadPreviewTableProps) {
  const [query, setQuery] = useState('');
  const [selectedOptionId, setSelectedOptionId] = useState(VALID_ROWS);
  const [activeRows, setActiveRows] = useState(props.validRows);
  useEffect(() => {
    switch (selectedOptionId) {
      case INVALID_ROWS:
        setActiveRows(props.invalidRows);
        break;
      case VALID_ROWS:
        setActiveRows(props.validRows);
        break;
      case SUCCESS_ROWS:
        setActiveRows(props.successRows);
        break;
      case FAILED_ROWS:
        setActiveRows(props.failedRows);
        break;
      default:
        setActiveRows([]);
        break;
    }
  }, [selectedOptionId, props.validRows, props.invalidRows, props.failedRows, props.successRows]);

  const handleOnChange = ({ queryText, error }: onChangeArgument) => {
    if (!error) {
      setQuery(queryText);
    }
  };

  const facets = [
    {
      id: VALID_ROWS,
      label: 'Valid Rows',
      quantity: props.validRows.length,
      isSelected: selectedOptionId === VALID_ROWS,
      onClick: () => {
        setSelectedOptionId(VALID_ROWS);
      },
    },
    {
      id: INVALID_ROWS,
      label: 'Invalid Rows',
      quantity: props.invalidRows.length,
      isSelected: selectedOptionId === INVALID_ROWS,
      onClick: () => {
        setSelectedOptionId(INVALID_ROWS);
      },
    },
    {
      id: SUCCESS_ROWS,
      label: 'Successful Uploads',
      quantity: props.successRows.length,
      isSelected: selectedOptionId === SUCCESS_ROWS,
      onClick: () => {
        setSelectedOptionId(SUCCESS_ROWS);
      },
    },
    {
      id: FAILED_ROWS,
      label: 'Failed Uploads',
      quantity: props.failedRows.length,
      isSelected: selectedOptionId === FAILED_ROWS,
      onClick: () => {
        setSelectedOptionId(FAILED_ROWS);
      },
    },
  ];

  const renderToolsLeft = () => {
    if (selectedOptionId === VALID_ROWS) {
      return [
        <EuiButton
          id="add-new-devices-by-file-upload"
          color="primary"
          fill
          isDisabled={props.isLoading}
          isLoading={props.isLoading}
          key="submitValid"
          onClick={props.onSubmit}
          type="submit"
        >
          {props.submitButtonText}
        </EuiButton>,
      ];
    }

    return undefined;
  };

  const search: EuiSearchBarProps = {
    query,
    onChange: handleOnChange,
    box: {
      schema: true,
    },
    toolsLeft: renderToolsLeft(),
  };

  return (
    <EuiFlexGroup>
      <EuiFlexItem grow={1}>
        <EuiFacetGroup>
          {facets.map(facet => {
            return (
              <EuiFacetButton
                id={facet.id}
                isSelected={facet.isSelected}
                key={facet.id}
                onClick={facet.onClick}
                quantity={facet.quantity}
              >
                {facet.label}
              </EuiFacetButton>
            );
          })}
        </EuiFacetGroup>
      </EuiFlexItem>
      <EuiFlexItem grow={4}>
        <EuiInMemoryTable
          columns={props.tableColumns}
          items={activeRows}
          loading={props.isLoading}
          pagination
          search={search}
          sorting
          tableCaption="Upload data"
        />
      </EuiFlexItem>
    </EuiFlexGroup>
  );
}
