import {
  EuiErrorBoundary,
  EuiScreenReaderOnly,
  DefaultItemAction,
  EuiTableFieldDataColumnType,
  EuiSearchBarProps,
  EuiInMemoryTable,
} from '@elastic/eui';
import { FotaRequestRow, FotaType, FotaRequestState } from 'gqlHooks';

export interface SntFotaRequestsTableProps {
  /** Error text to display in the table if something is wrong */
  errorText?: string;
  /** True if the table should show a loading status */
  isLoading: boolean;
  /** Fota requests to be displayed in the table */
  requests: FotaRequestRow[];
  /** Method invoked when the users wish to cancel a request */
  onRequestCancel: (requestId: number, deviceId: string) => void;
  /** Which columns should be displayed in the table */
  tableColumns: EuiTableFieldDataColumnType<FotaRequestRow>[];
}

/* Table for displaying message event data */
export default function SntFotaRequestsTable(props: SntFotaRequestsTableProps) {
  // Action for cancelling a request
  const cancelRequest = (request: FotaRequestRow) => {
    props.onRequestCancel(request.requestId, request.deviceId);
  };

  const cancelAction: DefaultItemAction<FotaRequestRow> = {
    name: (
      <EuiScreenReaderOnly>
        <span>Cancel Request</span>
      </EuiScreenReaderOnly>
    ),
    available: (request: FotaRequestRow) => {
      const requestState = request.requestState;
      return requestState === FotaRequestState.Created;
    },
    enabled: (request: FotaRequestRow) => {
      const requestState = request.requestState;
      return requestState === FotaRequestState.Created;
    },
    description: 'Cancel FOTA Request',
    icon: 'trash',
    color: 'danger',
    type: 'icon',
    onClick: cancelRequest,
    isPrimary: true,
  };

  const actions = [cancelAction];

  const search: EuiSearchBarProps = {
    box: {
      incremental: true,
    },
    filters: [
      {
        type: 'field_value_selection',
        field: 'requestState',
        name: 'Status',
        multiSelect: false,
        options: Object.values(FotaType).map(type => ({
          value: type,
        })),
      },
    ],
  };
  return (
    <EuiErrorBoundary>
      <EuiInMemoryTable
        search={search}
        columns={[
          ...props.tableColumns,
          {
            name: 'Actions',
            actions,
          },
        ]}
        error={props.errorText}
        hasActions
        itemId={'requestId'}
        items={props.requests}
        loading={props.isLoading}
        tableCaption="Message Events"
      />
    </EuiErrorBoundary>
  );
}
