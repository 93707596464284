import { useState } from 'react';
import { EuiComboBoxOptionOption, EuiErrorBoundary } from '@elastic/eui';
import { useForm } from 'react-hook-form';
import type { SubmitHandler } from 'react-hook-form';
import {
  AddDeviceToOrganizationMutationVariables,
  UserInfo,
  useAddDeviceToGroupMutation,
  useAddDeviceToOrganizationMutation,
} from 'gqlHooks';
import { UserInfoContextData, useUserInfo } from 'app/context/userInfo';
import { Toast } from '@elastic/eui/src/components/toast/global_toast_list';
import { errorToastMessage, successToastMessage } from 'app/utils/toast-messages';

import AddDeviceToOrganizationFormView from './view';

const DEFAULT_VALUES: AddDeviceToOrganizationMutationVariables = {
  deviceId: '',
  organizationId: '',
  label: '',
  deviceType: '',
};

type DeviceToOrgProps = {
  /** Handler for adding toasts to the global list */
  addToastMessage: (toasty: Toast) => void;
  userOrganizationId: string;
};

export const AddDeviceToOrganizationForm = (props: DeviceToOrgProps) => {
  const [defaults, setDefaults] = useState(DEFAULT_VALUES);
  const [errorState, setErrorState] = useState<string | undefined>();
  const [selectedGroup, setSelectedGroup] = useState<EuiComboBoxOptionOption[]>([]);
  const {
    queryData: { userInfo },
  }: UserInfoContextData = useUserInfo();

  const userOrg: UserInfo['organizationId'] = userInfo?.organizationId ?? '';
  const isUserAdmin: boolean = userInfo?.role === 'administrator';

  const { control, getValues, handleSubmit, reset } =
    useForm<AddDeviceToOrganizationMutationVariables>({
      mode: 'all',
      shouldFocusError: true,
      defaultValues: defaults,
    });

  const [addDeviceToOrganizationMutation, { loading: isSubmitting }] =
    useAddDeviceToOrganizationMutation({
      onCompleted: () => {
        // Set the last used device type and org to drop user pain a little on multi-entry
        const { organizationId, deviceType } = getValues();
        const newDefaults = { ...defaults, organizationId, deviceType };
        setDefaults(newDefaults);
        reset(newDefaults);
        props.addToastMessage(successToastMessage('Added device'));
      },
      onError: () => {
        props.addToastMessage(errorToastMessage('Failed to add device'));
      },
    });

  const onSubmit: SubmitHandler<AddDeviceToOrganizationMutationVariables> = () => {
    const { deviceId, organizationId, label, deviceType } = getValues(); // Access react-hook-form values
    addDeviceToOrganizationMutation({
      variables: {
        deviceId: deviceId,
        organizationId: isUserAdmin ? organizationId : userOrg,
        label: label,
        deviceType: deviceType,
      },
    });
    addDeviceToGroup({
      variables: {
        organizationId: organizationId,
        deviceId: deviceId,
        groupToken: (selectedGroup[0]?.value as string) ?? '',
      },
    });
  };

  // Add a device to the group
  const [addDeviceToGroup, { loading: isAddingToGroup }] = useAddDeviceToGroupMutation({
    onCompleted: () => {
      setSelectedGroup([]);
      props.addToastMessage(successToastMessage('Added device to group'));
    },
    onError: () => props.addToastMessage(errorToastMessage('Failed to add device to group')),
  });

  const handleCalloutDismiss = () => {
    setErrorState(undefined);
  };

  return (
    <EuiErrorBoundary>
      <AddDeviceToOrganizationFormView
        setSelectedGroup={setSelectedGroup}
        selectedGroup={selectedGroup}
        onSubmit={handleSubmit(onSubmit)}
        control={control}
        isAdmin={isUserAdmin}
        isLoading={isSubmitting || isAddingToGroup}
        handleCalloutDismiss={handleCalloutDismiss}
        errorMessage={errorState}
        userOrg={userOrg}
      />
    </EuiErrorBoundary>
  );
};
